import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlogGrid from "../components/blog-grid";
import SectionTitle from "../components/sectiontitle";
import Pagination from "../components/pagination";
import Seo from "../components/seo";

// This component is the basic blog-list grid template, rendering whatever posts
// are passed to it via query. It is used in both the /blog/ page 


export default function BlogIndex({ data, pageContext }) {
  return (
    <Layout>
      <Seo lang="en" title="Blog" />
      {data.allMarkdownRemark.edges.length > 0
        ? <>
          <section id="blog" className="container">
            <div className="section-title">
              <SectionTitle title="Blog" />
            </div>
            <BlogGrid data={data} />
            <Pagination
              pageContext={pageContext}
              type="blog"
            />
          </section>
          {/* <section>
            <EmailSignup />
          </section> */}
        </>
        : <>
        </>
      }
    </Layout>
  );
}

export const query = graphql`
query ($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/blog/"} }
    sort: {fields: [frontmatter___date], order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          description
          date
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              id
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`;
